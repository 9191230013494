.fp {
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .fpItem{
    flex: 1;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .fpImg{
    width: 100%;
    height: 250px;
    transition: 0.3s ease-in-out; 
    object-fit: cover;
    cursor: pointer;
  }
.fpItem:hover img{
  transform: scale(1.3);
}
  
  .fpName{
    color: #333;
    font-weight: bold;
  }
  
  .fpCity{
    font-weight: 300;
  }
  
  .fpPrice{
    font-weight: 500;
  }
  
  .fpRating>button{
    background-color: #003580;
    color: white;
    border: none;
    padding: 3px;
    margin-right: 10px;
    font-weight: bold;
  }
  
  .fpRating>span{
    font-size: 14px;
  }